import Vue from 'vue';
import Vuex from 'vuex';
import wortener from './wortener';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: false,
    modules: {
        wortener
    }
})
