import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import router from './router'
import store from './vuex/store'
import { domain } from '@/config'
// import './plugins/element.js'

import TreeTable from 'vue-table-with-tree-grid'

// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'

// 导入NProgress 对应的js和css
import NProgress from 'nprogress'

import axios from 'axios'
// 在request拦截器中展示进度条 NProgress.start()
axios.defaults.baseURL = `${domain}/api/private/v1/`
// axios.defaults.baseURL = 'https://www.liulongbin.top:8888/api/private/v1/'
axios.interceptors.request.use(config => {
  NProgress.start()
  // 登录授权 请求验证是否有token  需要授权的 API ，必须在请求头中使用 `Authorization` 字段提供 `token` 令牌
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config // 必须返回否则没有值
})
// 在reponse拦截器中,隐藏进度条 NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
Vue.prototype.$http = axios
Vue.use(VueLazyload)

Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)
// 全局时间过滤器
Vue.filter('dataFormat', function (originVal, format = 'yyyy-mm-dd') {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  if (format = 'yyyy-mm-dd') {
    return `${y}-${m}-${d}`
  }
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
