import http from './http.js'
async function request({method = "get", url, data = {}, params = {}, download = ''}) {
  let result = await http({
    method,
    url,
    data,
    params,
    download
  })
  if (result && result.message) {
    throw(result.message)
  } else {
    return result
  }
}
export default request