import axios from 'axios'
import NProgress from 'nprogress'
import Cookies from 'js-cookie'
import router from '../router/index'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import { domain } from '@/config'
// 请求
const http = axios.create({
    // baseURL: 'https://lianghj.top:8888/api/private/v1/',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : domain,
    timeout: 1000 * 60 * 5,
    withCredentials: true
})
// 请求拦截
// 在request拦截器中展示进度条 NProgress.start()
http.interceptors.request.use(config => {
  if (config.download) {
    config.headers.responseType = 'blob'
  }
  if (process.env.NODE_ENV === 'development') {
    // Cookies.set('mall', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IjEwIiwidWlkIjoxMCwidHlwZSI6MiwiaWF0IjoxNjU3NDU4NDE1fQ.ba5-yJ2h8FaLSMaX_tryWct3BR4DFg8cw7PUOPuj7bw')
    config.url = '/api' + config.url
  }
  // NProgress.start()
  // 登录授权 请求验证是否有token  需要授权的 API ，必须在请求头中使用 `Authorization` 字段提供 `token` 令牌
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  return config // 必须返回否则没有值
})
// 响应拦截
// 在reponse拦截器中,隐藏进度条 NProgress.done()
http.interceptors.response.use(res => {
  // NProgress.done()
  let { status, config } = res
  let data = res.data || {}
  if (status === 200) {
    if (config.download) {
      // const link = document.createElement('a')        
      // const blob = new Blob([res.data])        
      // link.href = window.URL.createObjectURL(blob)
      // link.download = config.download // 下载文件的名称
      // link.click()

      return
      // let blob = new Blob([res.data],{type: res.headers['content-type']});
      // // 创建新的URL并指向File对象或者Blob对象的地址
      // const blobURL = window.URL.createObjectURL(blob)
      // // 创建a标签，用于跳转至下载链接
      // const tempLink = document.createElement('a')
      // tempLink.style.display = 'none'
      // tempLink.href = blobURL
      // const contentDisposition = res.headers['content-disposition'] || `attachment;filename=${config.download}`;
      // tempLink.setAttribute('download', decodeURI(contentDisposition.split(';')[1].split('=')[1]))
      // // 兼容：某些浏览器不支持HTML5的download属性
      // if (typeof tempLink.download === 'undefined') {
      //   tempLink.setAttribute('target', '_blank')
      // }
      // // 挂载a标签
      // document.body.appendChild(tempLink)
      // tempLink.click()
      // document.body.removeChild(tempLink)
      // // 释放blob URL地址
      // window.URL.revokeObjectURL(blobURL)

      // const url = window.URL.createObjectURL(new Blob([res.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', config.download);
      // document.body.appendChild(link);
      // link.click();
      // 处理响应数据，例如保存为文件
      // const aLink = document.createElement('a');
      // const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
      // aLink.download = decodeURIComponent(config.download);
      // aLink.href = URL.createObjectURL(blob);
      // aLink.click();
      return
    }
    if (+data.code === 0) {
      return data.data || {}
    } else {
      return {
        message: data.message
      }
    }
  }
}, error => {
  let { message, config, code, request, response } = error
  if (+response.status >= 500) {
    Message.error(response.data.message)
    throw response.data.message
  }
  if (+response.status === 403) {
    Message.error('403：请重新登录')
    router.replace('/login')
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') > -1) {
    Message.error(`请求超时：${error.config.url}`)
    throw `请求超时：${error.config.url}`
  }
})
export default http;